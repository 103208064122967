$loadingColor: red;
$drawerWidth: 250px;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    background-color: #f6f8fc;
}

#root {
    width: 100%;
    height: 100%;
    background-color: #f6f8fc;
}

// main app bar
#appbar {
    position: fixed;
    background-color: inherit;
    left: $drawerWidth;
    top: 0px;
    min-width: 850px;
    right: 0px;
    height: 77px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

// the container other than app bar and drawer
#content {
    position: fixed;
    background-color: white;
    padding: 0px;
    top: 78px;
    bottom: 0px;
    left: $drawerWidth;
    right: 0px;
    margin: 0px;
    padding-top: 10px;
    overflow: auto;
    border-top-left-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.content-min-width {
    padding-bottom: 100px;
    min-width: 850px;
}

.question-input {
    border: none;
    font-size: large;
    padding-left: 5px;
    padding-top: 8px;
    padding-right: 5px;
    padding-bottom: 5px;
    background-color: rgb(47 128 237 / 18%);
    resize: none;
    font-family: inherit;
    font-size: inherit;
}

.question-input:focus {
    border: none;
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.bgcolor-yellow {
    background: rgba(251, 233, 138, 0.7);
}

.fill-in-gap-box {
    height: 20px;
    min-width: 30px;
    border: 1px #2f80ed solid;
    border-radius: 3px;
    display: inline-flex;
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
}

.dot-bricks {
    position: relative;
    top: 8px;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $loadingColor;
    color: $loadingColor;
    box-shadow: 9991px -16px 0 0 $loadingColor, 9991px 0 0 0 $loadingColor,
        10007px 0 0 0 $loadingColor;
    animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
    0% {
        box-shadow: 9991px -16px 0 0 $loadingColor, 9991px 0 0 0 $loadingColor,
            10007px 0 0 0 $loadingColor;
    }
    8.333% {
        box-shadow: 10007px -16px 0 0 $loadingColor, 9991px 0 0 0 $loadingColor,
            10007px 0 0 0 $loadingColor;
    }
    16.667% {
        box-shadow: 10007px -16px 0 0 $loadingColor,
            9991px -16px 0 0 $loadingColor, 10007px 0 0 0 $loadingColor;
    }
    25% {
        box-shadow: 10007px -16px 0 0 $loadingColor,
            9991px -16px 0 0 $loadingColor, 9991px 0 0 0 $loadingColor;
    }
    33.333% {
        box-shadow: 10007px 0 0 0 $loadingColor, 9991px -16px 0 0 $loadingColor,
            9991px 0 0 0 $loadingColor;
    }
    41.667% {
        box-shadow: 10007px 0 0 0 $loadingColor, 10007px -16px 0 0 $loadingColor,
            9991px 0 0 0 $loadingColor;
    }
    50% {
        box-shadow: 10007px 0 0 0 $loadingColor, 10007px -16px 0 0 $loadingColor,
            9991px -16px 0 0 $loadingColor;
    }
    58.333% {
        box-shadow: 9991px 0 0 0 $loadingColor, 10007px -16px 0 0 $loadingColor,
            9991px -16px 0 0 $loadingColor;
    }
    66.666% {
        box-shadow: 9991px 0 0 0 $loadingColor, 10007px 0 0 0 $loadingColor,
            9991px -16px 0 0 $loadingColor;
    }
    75% {
        box-shadow: 9991px 0 0 0 $loadingColor, 10007px 0 0 0 $loadingColor,
            10007px -16px 0 0 $loadingColor;
    }
    83.333% {
        box-shadow: 9991px -16px 0 0 $loadingColor, 10007px 0 0 0 $loadingColor,
            10007px -16px 0 0 $loadingColor;
    }
    91.667% {
        box-shadow: 9991px -16px 0 0 $loadingColor, 9991px 0 0 0 $loadingColor,
            10007px -16px 0 0 $loadingColor;
    }
    100% {
        box-shadow: 9991px -16px 0 0 $loadingColor, 9991px 0 0 0 $loadingColor,
            10007px 0 0 0 $loadingColor;
    }
}
